module.exports = {

	init: function(selector) {
		this.openTrigger = document.querySelector(selector)
		this.overlay = document.querySelector('.overlay')
		this.content = document.getElementById(this.openTrigger.getAttribute('data-overlay'))
		this.closeTrigger = this.overlay.querySelector('.close-icon')

		if (!this.openTrigger || !this.overlay || !this.content) return

		var overlayContents = this.overlay.querySelector('.overlay-content')

		overlayContents.innerHTML = this.content.outerHTML
		this.overlay.style.webkitTransform = 'translateZ(10px)'

		this.registerEventHandlers()
	},

	registerEventHandlers: function() {
		this.openTrigger.addEventListener('click', this.triggerHandler.bind(this))
		this.closeTrigger.addEventListener('click', this.closeHandler.bind(this))
	},

	triggerHandler: function(e) {
		this.overlay.classList.remove('hidden')

		// Disable scrolling by prevent default actions
		// on touchmove event
		window.addEventListener('touchmove', this.touchmoveHandler)

		setTimeout(function() {
			this.overlay.classList.add('active')
		}.bind(this), 0)
	},

	closeHandler: function(e) {
		var self = this
		var container = this.overlay.querySelector('.overlay-container')

		this.overlay.classList.remove('active')

		window.removeEventListener('touchmove', this.touchmoveHandler)

		if (container) {
			container.addEventListener(getTransitionEnd(), hideOverlay, true)
		}

		function hideOverlay() {
			self.overlay.classList.add('hidden')
			container.removeEventListener(getTransitionEnd(), hideOverlay, true)
		}
	},

	touchmoveHandler: function(e) {
		e.preventDefault()
	}

}

/**
 * Return the correct, vendor prefixed, transitionend event name.
 * Sourced from http://modernizr.com/docs/#prefixed
 */
function getTransitionEnd() {
	var transEndEventNames = {
		'WebkitTransition' : 'webkitTransitionEnd', // Saf 6, Android Browser
		'MozTransition'    : 'transitionend',       // only for FF < 15
		'transition'       : 'transitionend'        // IE10, Opera, Chrome, FF 15+, Saf 7+
	};

	return transEndEventNames[ Modernizr.prefixed('transition') ];
}
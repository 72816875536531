module.exports = contactForm = {
	init: function(ele) {
		var formFields = ele.querySelector('.form-fields')
		var formSuccess = ele.querySelector('.form-success')

		ele.addEventListener('submit', function(e) {
			e.preventDefault()
			var fields = Array.prototype.slice.call(e.target.querySelectorAll('input, select, textarea'))
			var data = fields.reduce(function(acc, field) {
				var tmp = Object.assign({}, acc)
				tmp[field.name] = field.value
				return tmp
			}, {})

			fetch(e.target.getAttribute('action'), {
				method: e.target.getAttribute('method'),
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			}).then(function(res) {
				return res.json()
			}).then(function(data) {
				if (!data.error) {
					formFields.classList.add('hidden')
					formSuccess.classList.remove('hidden')
				}
			})
		})
	}
}
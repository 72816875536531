require('es6-promise').polyfill()
require('isomorphic-fetch')

var mobileNavigation = require('./mobile-navigation')
var hero = require('./hero')
var gallery = require('./gallery')
var contactForm = require('./contact-form')

window.addEventListener('orientationchange', setOrientation)
setOrientation()

mobileNavigation.init('.mobile-nav-link')
hero.init('.hero')
gallery.init('.gallery-wrapper', '.nav-link.left', '.nav-link.right', window.__GALLERY_PHOTOS__, window.__GALLERY_THUMBNAILS__)

var formEle = document.querySelector('#contact-form')

if (formEle) {
	contactForm.init(formEle)
}

/**
 * Add class to html element to denote the
 * current device orientation
 */
function setOrientation() {
	var html = document.getElementsByTagName('html')[0]

	if (Math.abs(window.orientation) == 90) {
		html.classList.remove('portrait')
		html.classList.add('landscape')
	} else {
		html.classList.remove('landscape')
		html.classList.add('portrait')
	}
}
module.exports = {

	init: function(selector) {
		this.hero = document.querySelector(selector)
		this.header = document.getElementsByTagName('header')[0]

		if (!this.hero || !this.header) return

		this.heroImg = this.hero.querySelector('.hero-container')
		this.heroHeight = this.hero.offsetHeight
		this.content = document.querySelector('.hero + .content-container')

		this.topY = this.heroHeight - this.header.offsetHeight
		this.anchored = false

		this.registerEventListeners()
	},

	registerEventListeners: function() {
		window.addEventListener('scroll', function(e) {
			if (window.scrollY >= this.topY && !this.anchored) {
				this.anchored = true
				this.hero.style.zIndex = '7001'
				this.hero.style.height = this.heroHeight - this.topY + 'px'
				this.content.style.zIndex = '7000'
			} else if (window.scrollY < this.topY && this.anchored) {
				this.anchored = false
				this.hero.style.zIndex = null
				this.content.style.zIndex = null
			}

			if (!this.anchored) {
				this.hero.style.height = this.heroHeight - window.scrollY + 'px'
			}

			if (window.scrollY >= 0) {
				var scrolled = window.scrollY > this.topY ? this.topY : window.scrollY
				var scrollPercent = scrolled / this.topY
				var opacity = 1 + ((0.2 - 1) * scrollPercent)
				var top = -scrolled * 0.25

				this.heroImg.style.opacity = opacity
				this.heroImg.style.backgroundPosition = '0 ' + top + 'px'
			}
		}.bind(this))
	}

}
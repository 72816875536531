
module.exports = gallery = {

	init: function(selector, leftLink, rightLink, photos, thumbnails) {
		this.menuActive = false
		this.activeImg = null
		this.photos = photos
		this.thumbnails = thumbnails

		this.wrapper = document.querySelector(selector)
		this.leftLink = document.querySelector(leftLink)
		this.rightLink = document.querySelector(rightLink)

		if (!this.wrapper || !this.leftLink || !this.rightLink) return

		this.overlay = this.wrapper.querySelector('.menu-overlay')
		this.menuIcon = document.querySelector('.gallery-link.menu')

		this.menu = document.querySelector('.gallery-menu')
		this.closeIcon = document.querySelector('.gallery-link.close')

		this.slider = this.wrapper.querySelector('.slider')
		this.imgWrapper = this.slider.querySelector('.gallery-images')

		this.menuShowHide = document.querySelectorAll('.menu-show-hide')

		this.gallerySections = document.querySelectorAll('.gallery-thumbnails-wrapper')
		this.gallerySectionLinks = document.querySelectorAll('.gallery-links a')
		this.thumbnailElements = document.querySelectorAll('.thumbnail') 
		this.activeSection = this._getInitialActiveSection()
		this.activeImage = this._getInitialActiveImage()

		this.registerEventHandlers()
	},

	_getInitialActiveSection: function() {
		var galleryItems = document.querySelectorAll('.gallery-link-item')
		
		for (var i = 0, len = galleryItems.length; i < len; i++) {
			if (galleryItems[i].classList.contains('active')) {
				return i
			}
		}

		return 0
	},

	_getInitialActiveImage: function() {
		return document.querySelector('.thumbnail.active')
	},

	registerEventHandlers: function() {
		this.wrapper.addEventListener('mouseenter', this.enterView.bind(this))
		this.wrapper.addEventListener('mouseleave', this.leaveView.bind(this))
		this.menuIcon.addEventListener('click', this.menuIconClicked.bind(this))
		this.closeIcon.addEventListener('click', this.closeIconClicked.bind(this))
		this.closeIcon.addEventListener('touchend', this.closeIconTouchend.bind(this))
		this.leftLink.addEventListener('click', this.selectLeft.bind(this))
		this.rightLink.addEventListener('click', this.selectRight.bind(this))

		window.addEventListener('keydown', function(e) {
			if (e.which == 37) {
				this.selectLeft()
			} else if (e.which == 39) {
				this.selectRight()
			}
		}.bind(this))

		document.addEventListener('keyup', function(e) {
			if (e.which == 27) { // Escape
				this.closeIcon.dispatchEvent(new MouseEvent('click'))
			}
		}.bind(this))

		document.addEventListener('readystatechange', function() {
			// Hide the image wrapper so that we don't see the css transition
			this.imgWrapper.classList.add('prepping')

			this.initSlider()

			if (this.activeImage) {
				this.selectThumbnail({ currentTarget: this.activeImage, preventDefault: function() {} })
			}
			
			// Make the wrapper visible again
			setTimeout(function() {
				this.imgWrapper.classList.remove('prepping')
			}.bind(this), 500)
		}.bind(this))

		this.slider.addEventListener('touchstart', this.onTouchStart.bind(this))

		var self = this
		for (var i = 0, len = this.gallerySectionLinks.length; i < len; i++) {
			this.gallerySectionLinks[i].addEventListener('click', (function(i) {
				return function() {
					self.activateSection(i)
				}
			})(i))
		}

		for (var i = 0, len = this.thumbnailElements.length; i < len; i++) {
			this.thumbnailElements[i].addEventListener('click', this.selectThumbnail.bind(this))
		}
	},

	onTouchStart: function(event) {
		event.preventDefault()

		var self = this
		var touchStart = event.touches[0].pageX
		var maxWidth = this.slider.offsetWidth
		var startPos = parseInt(self.imgWrapper.style.left.replace('px', ''))
		var endPos = startPos - maxWidth

		if (!touchStart) return

		function onTouchMove(e) {
			e.preventDefault(e)

			var touchMove = e.touches[0].pageX
			var delta = touchStart - touchMove
			var pos = parseInt(self.imgWrapper.style.left.replace('px', ''))

			if (pos <= 0 && -pos <= self.imgWrapper.querySelector('.image:last-child').offsetLeft) {
				self.imgWrapper.style.left = startPos-delta + 'px'
			} else {
				self.imgWrapper.style.left = startPos-delta/2 + 'px'
			}
		}

		function onTouchEnd(e) {
			var touchEnd = e.changedTouches[0].pageX
			var delta = touchStart - touchEnd

			transition('in', self.imgWrapper, 'animate-in', function() {
				self.imgWrapper.classList.remove('animate-in', 'active')
			})

			if (delta > 0 && delta >= maxWidth / 2) {
				if (self.activeImg.nextElementSibling) {
					self.selectRight()
				} else {
					self.selectCurrent()
				}
			} else if (delta < 0 && -delta >= maxWidth / 2) {
				if (self.activeImg.previousElementSibling) {
					self.selectLeft()
				} else {
					self.selectCurrent()
				}
			} else {
				self.selectCurrent()
			}

			self.slider.removeEventListener('touchmove', onTouchMove)
			self.slider.removeEventListener('touchend', onTouchEnd)
		}

		this.slider.addEventListener('touchmove', onTouchMove)
		this.slider.addEventListener('touchend', onTouchEnd)
	},

	enterView: function(e) {
		if (!this.menuActive) {
			this.showHideOverlay(true)
			this.showHideMenuIcon(true)
			this.showHideMenu(false)
		}
	},

	leaveView: function(e) {
		if (!this.menuActive) {
			this.showHideOverlay(false)
			this.showHideMenuIcon(false)
		}
	},

	showHideOverlay: function(show) {
		if (show) {
			this.overlay.classList.add('visible')
		} else {
			this.overlay.classList.remove('visible')
		}
	},

	showHideMenuIcon: function(show, noAnimate) {
		if (show) {
			this.menuIcon.style.display = null

			if (!noAnimate) {
				this.menuIcon.classList.add('visible')
			}
		} else {
			if (!noAnimate) {
				this.menuIcon.classList.remove('visible')
			} else {
				this.menuIcon.style.display = 'none'
			}
		}
	},

	showHideCloseIcon: function(show, noAnimate) {
		if (show) {
			this.closeIcon.style.display = null

			if (!noAnimate) {
				this.closeIcon.classList.add('visible')
			}
		} else {
			if (!noAnimate) {
				this.closeIcon.classList.remove('visible')
			} else {
				this.closeIcon.style.display = 'none'
			}
		}
	},

	showHideMenu: function(show, noOverlay, cb) {
		var self = this

		if (show) {
			this.menuActive = true
			for (var i = 0; i < this.menuShowHide.length; i++) {
				this.menuShowHide[i].classList.add('visible')
			}
			if (!noOverlay) {
				this.showHideOverlay(false)
			}
			this.activateSection(this.activeSection)
		} else if (this.menuActive == true) {
			this.menuActive = false
			for (var i = 0; i < this.menuShowHide.length; i++) {
				this.menuShowHide[i].classList.remove('visible')
			}
			this.hideSection()
		}
		if (typeof cb == 'function') cb()
	},

	activateSection: function(sectionIndex) {
		var prevSection = this.gallerySections[this.activeSection]
		var newSection = this.gallerySections[sectionIndex]

		if (!newSection) return
		
		prevSection.classList.remove('active')
		newSection.classList.add('active')

		document.querySelector('.gallery-link-item.active').classList.remove('active')
		document.querySelectorAll('.gallery-link-item')[sectionIndex].classList.add('active')

		this.activeSection = sectionIndex
	},

	hideSection: function() {
		var section = this.gallerySections[this.activeSection]

		section.classList.remove('active')
	},

	menuIconClicked: function(e) {
		e.preventDefault()

		this.showHideMenuIcon(false, true)
		this.showHideMenu(true)
	},

	closeIconClicked: function(e) {
		e.preventDefault()

		var self = this

		this.showHideMenu(false, false, function() {
			setTimeout(function() {
				self.showHideMenuIcon(true)
			}, 20)
		})
	},

	closeIconTouchend: function(e) {
		e.preventDefault()

		var self = this

		this.showHideMenu(false, true, function() {
			setTimeout(function() {
				self.showHideMenuIcon(true)
			}, 20)
		})
	},

	initSlider: function() {
		var imgs = this.imgWrapper.querySelectorAll('.image')
		var width = 0

		for (var i = 0; i < imgs.length; i++) {
			width += imgs[i].offsetWidth + getTotalMargins(imgs[i]).horizontal
		}

		this.imgWrapper.style.width = width + 'px'
		this.imgWrapper.style.left = 0

		this.activeImg = imgs[0]
	},

	loadImage: function(slide) {
		if (slide.querySelector('img')) return

		var section = slide.getAttribute('data-section')
		var index = slide.getAttribute('data-index')

		if (!this.photos.hasOwnProperty(section) || !this.photos[section][index]) {
			return
		}

		var img = document.createElement('img')
		img.src = this.photos[section][index]

		slide.appendChild(img)
	},

	selectThumbnail: function(e) {
		var el = e.currentTarget;
		var section = el.getAttribute('data-section')
		var index = parseInt(el.getAttribute('data-index'))
		var img = this.imgWrapper.querySelector('[data-section="'+section+'"][data-index="'+index+'"]')
		var next = this.imgWrapper.querySelector('[data-section="'+section+'"][data-index="'+(index + 1)+'"]')

		if (!img) return
		this.selectImg(img, next)
		this.closeIconClicked(e)

		this.activateThumb(section, index)
	},

	activateThumb: function(section, index) {
		var newThumb = document.querySelector('.thumbnail[data-section="'+section+'"][data-index="'+index+'"]')

		if (!newThumb) return

		if (this.activeThumb) this.activeThumb.classList.remove('active')
		newThumb.classList.add('active')
		this.activeThumb = newThumb
	},

	selectCurrent: function() {
		this.imgWrapper.style.left = -this.activeImg.offsetLeft + 'px'
	},

	selectImg: function(img, next) {
		if (!img.querySelector('img')) {
			this.loadImage(img)
		}
		
		if (next && !next.querySelector('img')) {
			this.loadImage(next)
		}

		this.imgWrapper.style.left = -img.offsetLeft + 'px'
		this.activeImg = img
	},

	getSectionIndex: function(sectionName) {
		for (var i = 0, len = this.gallerySections.length; i < len; i++) {
			if (this.gallerySections[i].id == sectionName) {
				return i
			}
		}

		return -1
	},

	selectLeft: function(e) {
		if (e) e.preventDefault()

		var prev = this.activeImg.previousElementSibling
		var curPos = parseInt(this.imgWrapper.style.left.replace('px'))

		if (!prev) return

		if (!prev.querySelector('img')) {
			this.loadImage(prev)
		}

		this.imgWrapper.style.left = -prev.offsetLeft + 'px'
		this.activeImg = prev

		var section = prev.getAttribute('data-section')
		var index = prev.getAttribute('data-index')
		var sectionIndex = this.getSectionIndex(section)
		this.activateThumb(section, index)
		this.activeSection = sectionIndex
	},

	selectRight: function(e) {
		if (e) e.preventDefault()

		var next = this.activeImg.nextElementSibling
		var curPos = parseInt(this.imgWrapper.style.left.replace('px'))

		if (!next) return

		if (next.nextElementSibling && !next.nextElementSibling.querySelector('img')) {
			this.loadImage(next.nextElementSibling)
		}

		this.imgWrapper.style.left = -next.offsetLeft + 'px'	
		this.activeImg = next

		var section = next.getAttribute('data-section')
		var index = next.getAttribute('data-index')
		var sectionIndex = this.getSectionIndex(section)
		this.activateThumb(section, index)
		this.activeSection = sectionIndex
	}

}

/**
 * Return the correct, vendor prefixed, transitionend event name.
 * Sourced from http://modernizr.com/docs/#prefixed
 */
function getTransitionEnd() {
	var transEndEventNames = {
		'WebkitTransition' : 'webkitTransitionEnd', // Saf 6, Android Browser
		'MozTransition'    : 'transitionend',       // only for FF < 15
		'transition'       : 'transitionend'        // IE10, Opera, Chrome, FF 15+, Saf 7+
	};

	return transEndEventNames[ Modernizr.prefixed('transition') ];
}

function getTotalMargins(el) {
	var marginStyle = window.getComputedStyle(el).margin
	var margins = marginStyle.split(' ')
	var marginsInt

	marginsInt = margins.map(function(m) {
		return parseInt(m.replace('px', ''))
	})

	return {
		horizontal: marginsInt[1] + marginsInt[3],
		vertical: marginsInt[0] + marginsInt[2] 
	}
}

function transition(inOut, el, transitionClass, cb) {
	var end = endTransition(transitionClass, cb)

	el.addEventListener(getTransitionEnd(), end)
	el.classList.add(transitionClass)

	if (inOut == 'in') {
		setTimeout(function() {
			el.classList.add('active')
		}, 20)
	} else {
		setTimeout(function() {
			el.classList.remove('active')
		}, 20)
	}

	function endTransition(removeClass, cb) {
		return function() {
			el.classList.remove(removeClass)
			el.removeEventListener(getTransitionEnd(), end)

			if (typeof cb == 'function') {
				cb()
			}
		}
	}
}

window.gallery = gallery